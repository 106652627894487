import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function stocklogchartUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'warehouse_name', label: '仓库名称',},
    { key: 'product_name', label: '商品名称' },
    { key: 'search_time', label: '日期' },
    { key: 'purchase_in_num', label: '采购入库' },
    { key: 'allot_in_num', label: '调拨入库' },
    { key: 'return_in_num', label: '退货入库' },
    { key: 'produce_in_num', label: '生产入库' },
    { key: 'stock_reverse_in_num', label: '库存转换入库' },
    { key: 'other_in_num',headerTitle:'BOM生产单主成品入库+BOM生产单副产品坏蛋入库+销售出库作废入库+状态转移入库+库存盘点增加库存+库存盘点转换库存(修改)+研发库存调整增加库存', label: '其它入库' },
    { key: 'total_in_num',headerTitle:'所有入库数量之和', label: '总入库' },
    { key: 'sales_out_num', label: '销售出库' },
    { key: 'allot_out_num', label: '调拨出库' },
    { key: 'produce_out_num', label: '生产出库' },
    { key: 'stock_reverse_out_num', label: '库存转换出库' },
    { key: 'other_out_num', label: '其它出库' },
    { key: 'other_out_num_one',headerTitle:'研发库存调整减少库存+库存盘点减少库存+状态转移出库+采购退货出库+BOM生产单主原料出库', label: '其它出库之和' },
    { key: 'total_out_num',headerTitle:'所有出库数量之和', label: '总出库' },
    { key: 'start_num',headerTitle:'仓库盘点表获取初始库存', label: '期初库存' },
    { key: 'end_num',headerTitle:'期初库存+总入库-总出库', label: '理论剩余库存' },
    { key: 'rate',headerTitle:'总出库/总入库', label: '动销比' },

  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('warehouse_name')
  const isSortDirDesc = ref(true)
  const condition = ref({
   warehouseId:"344",
   warehouseName:"快驴嘉兴鸡蛋仓",
   productName:"",
   searchTime:"",

  })

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('stocklog/searchChart', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        productName:condition.value.productName,
        warehouseId:condition.value.warehouseId,
        searchTime:condition.value.searchTime,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存日志报表列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition
    // Extra Filters
  }
}
