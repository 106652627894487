<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
          </b-col>
        </b-row>
        <b-form
                @submit.prevent="refetchDatas"
                @reset.prevent="resetCondition"
        >
          <b-row>
          <b-col
                  cols="12"
                  md="4"
          >
            <xy-input-button
                    label="仓库名称"
                    type="input"
                    v-on:change="fromChildren($event,['warehouseName','warehouseId'])"
                    :params="['warehouseName','warehouseId']"
                    :value="condition.warehouseName"
                    modalName="仓库"
                    placeholder="点击选择仓库"
            >
            </xy-input-button>
          </b-col>

          <b-col
                  cols="12"
                  md="4"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="product_name"
                    label-size="sm"
                    placeholder="请输入商品名称"
            >
              <b-form-input
                      v-model="condition.productName"
                      name="productName"
                      class="d-inline-block mr-1"
                      size="sm"
              />
            </b-form-group>
          </b-col>


          <b-col md="4" cols="12">
            <xy-input-button label="日期"
                             type="time"
                             v-on:change="fromChildren($event,['searchTime'])"
                             :params="['searchTime']"
                             :value="condition.searchTime"
            >
            </xy-input-button>
          </b-col>

          <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
          >
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
            >
              <span class="align-right">查询</span>
            </b-button>

            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
            >
              <span class="align-right">重置</span>
            </b-button>


            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="exportData"
            >
              <span class="align-right">导出</span>
            </b-button>
          </b-col>
          </b-row>
        </b-form>


      </div>
      <!--表数据-->
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

      </b-table>
      <!--页脚-->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BIcon, BootstrapVueIcons,
  VBTooltip,BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isNumber } from '@core/utils/filter'
import stocklogchartUseList from '@/views/apps/stocklog/StockLogChart/stocklogchartUseList'
import stocklogStore from '@/views/apps/stocklog/stocklogStore'
import Ripple from 'vue-ripple-directive'
import XyInputButton from "@/views/components/xy/XyInputButton";
import {useToast} from "vue-toastification/composition";
import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
    BAlert,
    XyInputButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  methods: {
    resetCondition() {
      this.condition = {}
      this.refetchData();
    },
    refetchDatas() {
     this.start =  isNumber(1);
      this.refetchData();
    },
  },
  props: {

  },
  data(){
    return{
      searchTime:"", //日期
      productName:"", //商品名称
      warehouseName:"", //仓库名称
      warehouseId:"", //仓库id
    }
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stocklog')) store.registerModule('stocklog', stocklogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stocklog')) store.unregisterModule('stocklog')
    })


    const exportData = function () {
      const condition = this.condition
        console.log(condition)
      axios({
            url: '/api/exportexcelfile/exportStockLogChart',
            method:'get',
            params: {
                productName:condition.productName,
                warehouseId:condition.warehouseId,
                searchTime:condition.searchTime,
            },
            responseType: 'blob',
          }
      ).then(res => {
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "库存日志报表-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }


    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }



    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition

      // UI
    } = stocklogchartUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,
      // UI
      exportData,
      fromChildren,
      condition

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
